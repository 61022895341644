<template>
<div>
  <div class="access">
    <img class="parking" src="../assets/lx100.webp">
    <h2 class="name_h2">アレンザ LX100</h2>

    <p class="access_p">
    アレンザ LX100は、プレミアムなSUVに向けて発売されたコンフォートタイヤです。<br>
    ふらつきに強く安定性があり、街乗り中心のSUVにおすすめです。<br>
    また、静粛性に特化したタイヤで乗り心地にも優れています。
    </p>

    <div class="square-01">
    </div>

    <h3 class="recom_h3">推奨車種</h3>
    <p class="recom">SUV</p>

    <div class="charge2">
    <div class="charge_table">
      <h2 class="charge_table_h2"><span class="charge_table_span">サイズ表・メーカー希望小売価格</span><br>※4本購入で値引きできます</h2>
      <table class="biz-hour">
        <tbody>
          <tr>
            <th>サイズ</th>
            <th>税込<span class="price_span"> 1本</span></th>
          </tr>
          <tr>
            <td>22インチ</td>
          </tr>
          <tr>
            <td>285/40R22 106W</td>
            <td class="reds"><span class="red_p">¥</span>116,820</td>
          </tr>
          <tr>
            <td>265/50R22 109V</td>
            <td class="reds"><span class="red_p">¥</span>73,590</td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr>
            <td>21インチ</td>
          </tr>
          <tr>
            <td>295/35R21 107W XL</td>
            <td class="reds"><span class="red_p">¥</span>122,760</td>
          </tr>
          <tr>
            <td>235/45R21 97W</td>
            <td class="reds"><span class="red_p">¥</span>79,200</td>
          </tr>
          <tr>
            <td>225/45R21 95W</td>
            <td class="reds"><span class="red_p">¥</span>75,680</td>
          </tr>
          <tr>
            <td>275/50R21 110V</td>
            <td class="reds"><span class="red_p">¥</span>86,130</td>
          </tr>
          <tr>
            <td>235/50R21 101W</td>
            <td class="reds"><span class="red_p">¥</span>63,690</td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr>
            <td>20インチ</td>
          </tr>
          <tr>
            <td>315/35R20 110W XL</td>
            <td class="reds"><span class="red_p">¥</span>118,580</td>
          </tr>
          <tr>
            <td>275/40R20 106W XL</td>
            <td class="reds"><span class="red_p">¥</span>98,340</td>
          </tr>
          <tr>
            <td>275/45R20 110W XL</td>
            <td class="reds"><span class="red_p">¥</span>83,930</td>
          </tr>
          <tr>
            <td>255/45R20 101W</td>
            <td class="reds"><span class="red_p">¥</span>79,200</td>
          </tr>
          <tr>
            <td>245/45R20 103W XL</td>
            <td class="reds"><span class="red_p">¥</span>75,680</td>
          </tr>
          <tr>
            <td>235/45R20 100W XL</td>
            <td class="reds"><span class="red_p">¥</span>72,380</td>
          </tr>
          <tr>
            <td>285/50R20 112V</td>
            <td class="reds"><span class="red_p">¥</span>72,820</td>
          </tr>
          <tr>
            <td>275/50R20 109W</td>
            <td class="reds"><span class="red_p">¥</span>70,290</td>
          </tr>
          <tr>
            <td>235/50R20 104V XL</td>
            <td class="reds"><span class="red_p">¥</span>60,500</td>
          </tr>
          <tr>
            <td>265/55R20 113V XL</td>
            <td class="reds"><span class="red_p">¥</span>63,360</td>
          </tr>
          <tr>
            <td>265/60R20 112H</td>
            <td class="reds"><span class="red_p">¥</span>58,960</td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr>
            <td>19インチ</td>
          </tr>
          <tr>
            <td>285/45R19 111V XL</td>
            <td class="reds"><span class="red_p">¥</span>80,740</td>
          </tr>
          <tr>
            <td>255/50R19 107V XL</td>
            <td class="reds"><span class="red_p">¥</span>60,500</td>
          </tr>
          <tr>
            <td>235/50R19 99V</td>
            <td class="reds"><span class="red_p">¥</span>55,440</td>
          </tr>
          <tr>
            <td>265/55R19 109V</td>
            <td class="reds"><span class="red_p">¥</span>58,190</td>
          </tr>
          <tr>
            <td>235/55R19 101V</td>
            <td class="reds"><span class="red_p">¥</span>52,910</td>
          </tr>
          <tr>
            <td>225/55R19 99V</td>
            <td class="reds"><span class="red_p">¥</span>50,490</td>
          </tr>
          <tr>
            <td>235/60R19 103V</td>
            <td class="reds"><span class="red_p">¥</span>50,160</td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr>
            <td>18インチ</td>
          </tr>
          <tr>
            <td>225/50R18 95V</td>
            <td class="reds"><span class="red_p">¥</span>50,490</td>
          </tr>
          <tr>
            <td>215/50R18 92V</td>
            <td class="reds"><span class="red_p">¥</span>48,180</td>
          </tr>
          <tr>
            <td>255/55R18 109V XL</td>
            <td class="reds"><span class="red_p">¥</span>54,450</td>
          </tr>
          <tr>
            <td>235/55R18 100V</td>
            <td class="reds"><span class="red_p">¥</span>49,830</td>
          </tr>
          <tr>
            <td>225/55R18 98V</td>
            <td class="reds"><span class="red_p">¥</span>48,180</td>
          </tr>
          <tr>
            <td>215/55R18 95V</td>
            <td class="reds"><span class="red_p">¥</span>46,640</td>
          </tr>
          <tr>
            <td>285/60R18 116V</td>
            <td class="reds"><span class="red_p">¥</span>56,870</td>
          </tr>
          <tr>
            <td>275/60R18 113V</td>
            <td class="reds"><span class="red_p">¥</span>55,110</td>
          </tr>
          <tr>
            <td>265/60R18 110V</td>
            <td class="reds"><span class="red_p">¥</span>53,020</td>
          </tr>
          <tr>
            <td>235/60R18 103H</td>
            <td class="reds"><span class="red_p">¥</span>47,190</td>
          </tr>
          <tr>
            <td>225/60R18 100H</td>
            <td class="reds"><span class="red_p">¥</span>46,090</td>
          </tr>
          <tr>
            <td>265/65R18 114V</td>
            <td class="reds"><span class="red_p">¥</span>55,110</td>
          </tr>
          <tr>
            <td>235/65R18 106S</td>
            <td class="reds"><span class="red_p">¥</span>42,900</td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr>
            <td>17インチ</td>
          </tr>
          <tr>
            <td>235/55R17 99V</td>
            <td class="reds"><span class="red_p">¥</span>46,640</td>
          </tr>
          <tr>
            <td>225/60R17 99H</td>
            <td class="reds"><span class="red_p">¥</span>42,350</td>
          </tr>
          <tr>
            <td>215/60R17 96H</td>
            <td class="reds"><span class="red_p">¥</span>40,260</td>
          </tr>
          <tr>
            <td>275/65R17 115H</td>
            <td class="reds"><span class="red_p">¥</span>44,220</td>
          </tr>
          <tr>
            <td>265/65R17 112H</td>
            <td class="reds"><span class="red_p">¥</span>42,570</td>
          </tr>
          <tr>
            <td>245/65R17 107H</td>
            <td class="reds"><span class="red_p">¥</span>41,030</td>
          </tr>
          <tr>
            <td>235/65R17 108H XL</td>
            <td class="reds"><span class="red_p">¥</span>38,610</td>
          </tr>
          <tr>
            <td>225/65R17 102H</td>
            <td class="reds"><span class="red_p">¥</span>35,750</td>
          </tr>
          <tr>
            <td>265/70R17 115S</td>
            <td class="reds"><span class="red_p">¥</span>37,950</td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr>
            <td>16インチ</td>
          </tr>
          <tr>
            <td>215/65R16 98H</td>
            <td class="reds"><span class="red_p">¥</span>30,030</td>
          </tr>
          <tr>
            <td>215/70R16 100H</td>
            <td class="reds"><span class="red_p">¥</span>28,050</td>
          </tr>
          <tr>
            <td>175/80R16 91S</td>
            <td class="reds"><span class="red_p">¥</span>22,220</td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr>
            <td>15インチ</td>
          </tr>
          <tr>
            <td>195/80R15 96S</td>
            <td class="reds"><span class="red_p">¥</span>23,540</td>
          </tr>
        </tbody>
      </table>
    </div>
    </div>

    <CarouselsSuv/>

    <p class="about_p">ACCESS</p>
    <h1>勝間田タイヤ</h1>
    <p class="address_p">静岡県御殿場市茱萸沢241-1</p>
    <a href="https://goo.gl/maps/oabDqVGbjJQn65yk8">Google Maps</a>
  </div>
  <div>
    <Footer/>
  </div>
</div>
</template>

<script>
import CarouselsSuv from '@/components/CarouselsSuv.vue'
import Footer from '@/components/Footer.vue'
export default {
  name: 'HomeView',
  components: {
    CarouselsSuv,
    Footer
  }
}
</script>

<style scoped>
.recom_h3{
  text-align: left;
}
.recom{
  padding-bottom: 3rem;
  font-size: 0.95rem;
  font-weight: 500;
  line-height: 1.6rem;
}
.recom_list{
  padding-bottom: 1rem;
  font-size: 0.95rem;
  font-weight: 500;
  line-height: 1.6rem;
}
.recom_list2{
  padding-bottom: 6rem;
  font-size: 0.95rem;
  font-weight: 500;
  line-height: 1.6rem;
}
.access{
  padding-top: 3rem;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 6rem;
}
.access h1{
  margin-top: 0.8rem;
  padding-bottom: 0.5rem;
  text-align:left;
  font-size: 1.9rem;
}
.access p{
  text-align:left;
}
.access_p{
  padding-bottom: 2rem;
  font-size: 0.95rem;
  font-weight: 500;
  line-height: 1.7rem;
}

.access a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
  width: 160px;
  height: 50px;
  color: #333;
  font-size: 13px;
  font-weight: 700;
  text-decoration: none;
  background-color: #9099a2;
  border-radius: 40px;
  margin-top: 30px;
}
.access a::after {
  content: '';
  width: 10px;
  height: 10px;
  border-top: 2px solid #d5d5d5;
  border-right: 2px solid #d5d5d5;
  transform: rotate(45deg);
}

.name_h2{
  padding-top: 1rem;
  font-size: 1.5rem;
  text-align: left;
  padding-bottom: 2rem;
}

.about_p{
  margin-top: -4rem;
  font-size: 0.95rem;
  font-weight: 500;
  line-height: 1.6rem;
  padding-top: 4rem;
}
.address_p{
  font-weight: 600;
  font-size: 0.8rem;
}

nav {
  margin-top: -7rem;
  padding-bottom: 6rem;
  padding-left: 2rem;
  padding-right: 2rem;
  font-size: 0.8rem;
  text-align:left;
  line-height: 1.6rem;
}

nav a {
  font-weight: bold;
  text-decoration: none;
  color: #2c3e50;
}
nav a.router-link-exact-active {
  color: #42b983;
}
nav h3{
  font-size: 1.5rem;
}



.access img {
  width: 100%;
  padding-bottom: 2rem;
  padding-top: 1rem;
}

@media (max-width: 640px)
.page .moretodiscover .square-01 {
    width: 67.5vw;
    height: calc(100% - 25vw);
    position: absolute;
    top: 25vw;
    right: 0;
    background-color: #dae4e8;
}
.charge2{
  padding-bottom: 3rem;
  padding-top: 0rem;
}
@media screen and (min-width: 520px) {
  .charge_table table{
    margin: 0 auto;
    width: 70%;
  }
  .charge_table ul{
    text-align: center;
    display: inline-block;
  }
  ul{
    display: inline-block;
    text-align: center;
  }
  .price_class1{
    display: inline-block;
    text-align: center;
  }
  .price_class2{
    text-align: center;
    display: inline-block;
  }
  .price_class3{
    text-align: center;
    display: inline-block;
  }
}
@media screen and (min-width: 1280px) {
  .charge_table table{
    margin: 0 auto;
    width: 70%;
  }
  .charge_table ul{
    text-align: center;
    display: inline-block;
  }
  ul{
    display: inline-block;
    text-align: center;
  }
  .price_class1{
    display: inline-block;
    text-align: center;
  }
  .price_class2{
    text-align: center;
    display: inline-block;
    padding-right: 10%;
  }
  .price_class3{
    text-align: center;
    display: inline-block;
  }
}
.charge_table_h2{
  text-align: left;
}
.charge_table_span{
  font-size: 1rem;
}
.price_span{
  font-size: 0.8rem;
}
.charge_table{
  padding-top: 4rem;
  padding-bottom: 4rem;
}
.biz-hour {
  border-collapse: collapse;
}
@media screen and (max-width: 414px) {
  .biz-hour {/* スマホのときだけ親要素いっぱいに */
    width: 100%;
  }
}

.biz-hour th, .biz-hour td {
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-align: center;
  /* ２番目以降の線 */
  /* 色はお好みで */
  border-bottom: 1px solid black;
  font-weight: bold;
}

/* 上段の背景色 */
.biz-hour th {
  /* background: black; */
  font-weight: bold;
}

/* 土曜日の文字色 */
.biz-hour th.sat {
  color: #3db2da;
}

/* 日曜日の文字色 */
.biz-hour th.sun {
  color: #e66a6a;
}
</style>
